<template>
  <div class="question-box">
    <div class="question-container">
      <div class="question-content">
        <h2 class="title">{{ section.question.name }}</h2>
        <div class="markdown-body" v-html="section.question.description"></div>
        <div class="mb-3">
          <el-checkbox-group
            v-if="section.question.type === this.GLOBAL.choice.multiple"
            v-model="choice"
            :disabled="section.is_finish === 1"
          >
            <el-checkbox
              v-for="item in section.question.items"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <el-radio-group
            v-if="section.question.type === this.GLOBAL.choice.single"
            v-model="choice"
            :disabled="section.is_finish === 1"
          >
            <el-radio
              v-for="item in section.question.items"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
        <el-button type="primary" @click="submitAnwser" v-if="$store.getters.eduRole !== 'student' || section.is_finish === 0">提交答案</el-button>
        <el-button type="warning" @click="showAnwser" v-if="$store.getters.eduRole !== 'student'">显示答案</el-button>
      </div>
    </div>
    <link
      rel="stylesheet"
      href="https://haimakid-cdn.haimakid.net/web/css/github-markdown.min.css"
    />
  </div>
</template>

<script>
import { section, updateStudyStatus } from "@/api/course";

import hljs from "highlight.js";
import "highlight.js/styles/xcode.css";
const MarkdownIt = require("markdown-it");
const MarkdownItKatex = require("markdown-it-katex");

export default {
  name: "Question",
  data() {
    return {
      section: {
        id: 0,
        type: 0,
        content: [],
        question: {
          name: "",
          description: "",
          items: [],
        },
        is_finish: 0,
      },
      chapter: {
        id: 0,
      },
      markdownIt: null,
      choice: [],
    };
  },
  mounted() {
    this.chapter.id = Number(this.$route.params.id);
    this.section.id = this.$route.params.sid;
    this.markdownIt = new MarkdownIt({
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(str, { language: lang }).value;
          } catch (_) {
            console.log("hljs error");
          }
        }
        return "";
      },
    }).use(MarkdownItKatex);
    this.init();
  },
  methods: {
    init() {
      section({ id: this.section.id }).then((sectionRes) => {
        this.section = sectionRes.data;
        if (this.section.type === this.GLOBAL.question) {
          this.section.question.description = this.markdownIt.render(
            this.section.question.description
          );

          if (this.section.is_finish === 1) {
            this.showAnwser()
          }
        }
      });
    },
    submitAnwser() {
      let choiceStr = this.choice.toString()
      choiceStr = `[${choiceStr}]`
      if (choiceStr === this.section.question.answer) {
        if (this.$store.getters.eduRole === 'student') {
          updateStudyStatus({
            chapter_id: this.chapter.id,
            resource_id: this.section.id,
          }).then(() => {
            this.$message({
              message: '回答正确',
              type: 'success'
            })
            this.$emit('finished');
            this.section.is_finish = 1;
          });
        }
      } else {
        this.$message({
          message: '回答错误，再想想～',
          type: 'error',
        })
      }
    },
    showAnwser() {
      this.choice = this.section.question.type === 2? JSON.parse(this.section.question.answer)[0]:JSON.parse(this.section.question.answer);
    }
  },
};
</script>

<style lang="scss" scoped>
.question-box {
  padding: 5% 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: url(https://haimakid-cdn.haimakid.net/xinao/img/8547a358-75b3-4609-96d9-982e2b15143200002.jpeg)
    no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .question-container {
    margin: 0 auto;
    width: 75%;
    height: 100%;
    overflow: auto;

    .question-content {
      width: 95%;

      .title {
        margin: 20px 0;
        font-size: 24px;
        font-weight: bold;
      }

      .el-checkbox,
      .el-radio {
        width: 100%;
        margin-top: 20px;
        padding: 10px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }
      .el-checkbox.is-checked,
      .el-radio.is-checked {
        border-color: #409eff;
      }
    }
  }
}
</style>