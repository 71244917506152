<!--
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-12-13 16:22:17
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-13 18:56:05
 * @FilePath: /haimakid-xinao/src/views/chapter/components/LearnRanking.vue
-->
<template>
  <div class="px-5 pb-5">
    <div class="my-learn">
      <div class="classmate-item">
        <div class="left">
          <div>
            <p class="pb-2">本课程你已学习「{{ myLearn.finish_count }}」小节</p>
            <p class="flex items-center">班级排名：
              <svg-icon v-if="myRanking === 1" iconClass="first" style="width: 1.6rem;height: 1.6rem;"></svg-icon>
              <svg-icon v-else-if="myRanking === 2" iconClass="second" style="width: 1.6rem;height: 1.6rem;"></svg-icon>
              <svg-icon v-else-if="myRanking === 3" iconClass="third" style="width: 1.6rem;height: 1.6rem;"></svg-icon>
              <span v-else class="px-2">{{ myRanking }}</span>
            </p>
          </div>
        </div>
        <div class="right">
          <div v-if="myLearn.current_section.is_finish" class="text-green-500">本节已完成 <i class="el-icon-success"></i></div>
          <div v-else class="text-red-500">本节未完成 <i class="el-icon-error"></i></div>
        </div>
      </div>
    </div>
    <div class="classmate-list" v-loading="loading">
      <div v-for="(item, index) in rankingList" :key="item.id" class="classmate-item">
        <div class="left">
          <div class="pr-2">
            <svg-icon v-if="index === 0" iconClass="first" style="width: 1.6rem;height: 1.6rem;"></svg-icon>
            <svg-icon v-else-if="index === 1" iconClass="second" style="width: 1.6rem;height: 1.6rem;"></svg-icon>
            <svg-icon v-else-if="index === 2" iconClass="third" style="width: 1.6rem;height: 1.6rem;"></svg-icon>
            <span v-else class="px-2">{{ index + 1 }}</span>
          </div>
          <div class="mate-info">
            <div class="pb-2">{{ item.name }}</div>
            <div>本课程已学习「{{ item.finish_count }}」小节</div>
          </div>
        </div>
        <div class="right">
          <div v-if="item.current_section.is_finish" class="text-green-500">本节已完成 <i class="el-icon-success"></i></div>
          <div v-else class="text-red-500">本节未完成 <i class="el-icon-error"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassmateRanking } from '@/api/course'

export default {
  name: 'LearnRanking',
  props: {
    courseId: {
      type: Number,
      required: true,
    },
    sectionId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      myLearn: {
        finish_count: 0,
        current_section: {
          is_finish: 1,
        }
      },
      myRanking: 0,
      rankingList: [],
      loading: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.loading) {
        this.loading = true;
        getClassmateRanking({ course_id: this.courseId, section_id: this.sectionId }).then(res => {
          if (res.code === 1) {
            this.rankingList = res.data.list;
            this.myRanking = res.data.my_ranking;
            this.myLearn = res.data.owner;
          }
        }).finally(() => {
          this.loading = false;
        })
      }
      
    }
  }
  
}
</script>

<style lang="scss">
.classmate-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-top: 1px dashed #ccc;
  .left {
    display: flex;
    align-items: center;
    text-align: left;
  }
}
</style>
