<template>
  <div class="problem-box flex h-full" :class="[isZoom ? 'fullscreen' : '']">
    <div class="left w-1/2">
      <el-tabs
        class="text-left"
        type="border-card"
        :stretch="true"
        v-model="tabActive"
        @tab-click="handleTabsClick"
      >
        <el-tab-pane label="题目描述" name="description">
          <el-row class="mb-3">
            <el-col :span="8">
              <span class="time-box">
                <i class="el-icon-time"></i> {{ contest.limit_time }} ms
              </span>
            </el-col>
            <el-col :span="8">
              <span class="memory-box">
                <i class="el-icon-film"></i> {{ contest.memory }} KB
              </span>
            </el-col>
          </el-row>
          <div class="markdown-body mb-4" v-html="contest.description"></div>
          <p class="mb-2" style="color: #e6a23c">
            <i class="el-icon-info"></i>
            输出时每行末尾的多余空格，不影响答案正确性
          </p>
          <div
            class="markdown-body sample-box"
            v-for="(item, index) in contest.input_sample"
            :key="index"
          >
            <p>
              <span>样例用例 {{ index + 1 }}</span>
              <el-button type="text" @click="doCopy(item)">复制</el-button>
            </p>
            <pre><code>{{ item }}</code></pre>
          </div>
          <div
            class="markdown-body sample-box"
            v-for="(item, index) in contest.output_sample"
            :key="index + 1"
          >
            <p>
              <span>样例输出 {{ index + 1 }}</span>
              <el-button type="text" @click="doCopy(item)">复制</el-button>
            </p>
            <pre><code>{{ item }}</code></pre>
          </div>
        </el-tab-pane>
        <el-tab-pane label="运行结果" name="result">
          <div class="sample-box">
            <p>参考用例</p>
          </div>
          <div class="sample-box">
            <p>参考输入</p>
            <div class="p-4 border rounded whitespace-pre-wrap">{{ contest.input_sample[0] }}</div>
          </div>
          <div class="sample-box">
            <p>参考输出</p>
            <div class="p-4 border rounded whitespace-pre-wrap" v-html="contest.output_sample[0]"></div>
          </div>
          <div class="sample-box">
            <p class="result">运行结果</p>
            <div v-if="runCodeStatus === 0" class="p-4 border rounded">
              请运行代码
            </div>
            <div v-else-if="runCodeStatus === 1" class="p-4 border rounded">
              运行中...
            </div>
            <div v-else-if="runCodeStatus === 2">
              <el-result v-if="resultStatus" icon="success" title="代码正确"></el-result>
              <el-result v-else icon="error" title="错误" :subTitle="resultOutput"></el-result>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="提交记录" name="record">
          <el-table :data="records">
            <el-table-column prop="status" label="判题状态">
              <template slot-scope="scope">
                <el-tag type="info" size="mini" v-if="scope.row.status === 1"
                  >等待ing</el-tag
                >
                <template v-else>
                  <el-tag
                    type="success"
                    size="mini"
                    v-if="scope.row.result_status === 1"
                    >答案正确</el-tag
                  >
                  <el-tag
                    type="error"
                    size="mini"
                    v-else-if="scope.row.result_status === 0"
                    >答案错误</el-tag
                  >
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="提交时间"
              width="180"
            ></el-table-column>
            <el-table-column prop="cast_time" label="运行时间">
              <template slot-scope="scope">
                {{ scope.row.cast_time }}ms
              </template>
            </el-table-column>
            <el-table-column prop="memory" label="运行内存">
              <template slot-scope="scope"> {{ scope.row.memory }}MB </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="题解" name="answer">
          <template v-if="this.$store.getters.eduRole === 'student'">
            <template v-if="section.is_finish">
              <div class="flex justify-between items-center mb-2">
                <span class="font-bold">标程</span>
                <el-button type="text" @click="copyCode">复制</el-button>
              </div>
              <div class="markdown-body" v-html="contest.showCode"></div>
            </template>
            <p v-else>请先完成编程并运行代码。</p>
          </template>
          <template v-else>
            <div class="flex justify-between items-center mb-2">
              <span class="font-bold">标程</span>
              <el-button type="text" @click="copyCode">复制</el-button>
            </div>
            <div class="markdown-body" v-html="contest.showCode"></div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="right w-1/2">
      <div class="tool-box px-2">
        <div class="flex items-center">
          <el-select v-model="language" size="mini" @change="handleChangeProgramLang">
            <el-option
              v-for="item in languageList"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-tooltip content="运行代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="runCode">
              <svg-icon
                class="ml-2 icon2x cursor-pointer"
                iconClass="play"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tag
            v-if="runCodeStatus === 0"
            class="ml-2"
            size="small"
            effect="dark"
            >待运行</el-tag
          >
          <el-tag
            v-else-if="runCodeStatus === 2"
            class="ml-2"
            type="success"
            size="small"
            effect="dark"
            >运行完成</el-tag
          >
          <el-tag v-else class="ml-2" type="warning" size="small" effect="dark"
            >运行中</el-tag
          >
        </div>
        <div class="flex">
          <el-tooltip
            v-if="!isZoom"
            content="全屏"
            placement="bottom"
            effect="light"
          >
            <a class="tool-btn" @click="isZoom = true">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="zoom-in"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip
            v-if="isZoom"
            content="退出全屏"
            placement="bottom"
            effect="light"
          >
            <a class="tool-btn" @click="isZoom = false">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="zoom-out"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip content="保存代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="saveCode">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="save"
              ></svg-icon>
            </a>
          </el-tooltip>
        </div>
      </div>
      <ace-editor
        ref="ace"
        :config="aceEditorConfig"
        :content="code"
        @updateCodeValue="updateCodeVal"
        style="height: calc(100% - 40px); overflow: auto"
      ></ace-editor>
    </div>
    <link
      rel="stylesheet"
      href="https://haimakid-cdn.haimakid.net/web/css/github-markdown.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.5.1/katex.min.css"
    />
  </div>
</template>

<script>
import AceEditor from "./AceEditor.vue";
import {
  section,
  contest,
  codeResultList,
  runContestCode,
  saveContestCode,
  checkCodeResult,
  updateStudyStatus,
  getSaveCode,
} from "@/api/course";

import hljs from "highlight.js";
import "highlight.js/styles/xcode.css";
const MarkdownIt = require("markdown-it");
const MarkdownItKatex = require("markdown-it-katex");

export default {
  name: "Problem",
  components: {
    AceEditor,
  },
  data() {
    return {
      chapter: {
        id: 0,
      },
      section: {
        id: 0,
        type: 0,
        content: [],
      },
      contest: {
        id: 0,
        input_sample: [],
        output_sample: [],
        limit_time: 0,
        memory: 0,
        description: "",
        code: "",
        lang: "",
      },
      aceEditorConfig: {
        maxLines: 200,
        minLines: 15,
      },
      language: "C++",
      languageList: [
        {
          id: 0,
          label: "C++",
        },
        {
          id: 1,
          label: "Python3"
        }
      ],
      code: "",
      tabActive: "description",
      markdownIt: null,
      records: [],
      isZoom: false,
      resultOutput: "",
      resultStatus: 0,
      runCodeStatus: 0,
    };
  },
  computed: {},
  mounted() {
    this.chapter.id = Number(this.$route.params.id);
    this.section.id = this.$route.params.sid;
    this.markdownIt = new MarkdownIt({
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(str, { language: lang }).value;
          } catch (_) {
            console.log("hljs error");
          }
        }
        return "";
      },
    }).use(MarkdownItKatex);
    this.init();
  },
  methods: {
    init() {
      section({ id: this.section.id }).then((sectionRes) => {
        this.section = sectionRes.data;
        if (this.section.type === this.GLOBAL.problem) {
          contest({ id: this.section.contest_id }).then((contestRes) => {
            this.contest = contestRes.data;
            this.contest.description = this.markdownRender(
              this.contest.description
            );
            this.contest.showCode = this.markdownRender(
              this.setCodeLanguage(this.contest.code, "cpp")
            );
            this.contest.input_sample = JSON.parse(this.contest.input_sample);
            this.contest.output_sample = JSON.parse(this.contest.output_sample);
          });
          this.getUserSavedCode()
        }
      });
    },
    updateCodeVal(val) {
      this.code = val;
    },
    markdownRender(val) {
      return this.markdownIt.render(val);
    },
    setCodeLanguage(val, lang) {
      if (lang === "cpp") {
        return "``` cpp\n" + val + "\n```";
      } else {
        return val;
      }
    },
    copyCode() {
      this.$copyText(this.contest.code)
        .then(() => {
          this.$message({
            message: "复制成功",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("复制失败");
        });
    },
    doCopy(val) {
      this.$copyText(val)
        .then(() => {
          this.$message({
            message: "复制成功",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("复制失败");
        });
    },
    saveCode() {
      saveContestCode({
        contest_id: this.contest.id,
        code: this.code,
        input_sample: this.contest.input_sample[0],
        output_sample: this.contest.output_sample[0],
        lang: this.language,
      }).then((res) => {
        console.log(res.msg);
      });
    },
    runCode() {
      if (this.runCodeStatus !== 1) {
        this.runCodeStatus = 1;
        this.resultOutput = "";
        let timer = null;
        runContestCode({
          contest_id: this.contest.id,
          code: this.code,
          input_sample: this.contest.input_sample[0],
          output_sample: this.contest.output_sample[0],
          lang: this.language,
        })
          .then(() => {
            this.finishStudy();
            let i = 0;
            timer = setInterval(() => {
              checkCodeResult({
                contest_id: this.contest.id,
              }).then((res) => {
                i++;
                if (res.data.status === 2) {
                  clearInterval(timer);
                  this.resultOutput = res.data.result_output
                    ? res.data.result_output
                    : res.data.result_err_msg;
                  this.resultStatus = res.data.result_status;
                  this.tabActive = "result";
                  this.runCodeStatus = 2;
                } else if (i === 10) {
                  clearInterval(timer);
                  this.$message({
                    message: "运行超时，请稍后重试",
                    type: "error",
                  });
                  this.runCodeStatus = 0;
                }
              });
            }, 1500);
          })
          .catch(() => {
            clearInterval(timer);
            this.runCodeStatus = 0;
          });
      } else {
        this.$message({
          message: "代码正在运行中，请稍后重试",
          type: "warning",
        });
      }
    },
    handleTabsClick() {
      if (this.tabActive === "record") {
        codeResultList({ contest_id: this.contest.id }).then((res) => {
          this.records = res.data;
        });
      }
    },
    finishStudy() {
      if (this.section.is_finish === 0) {
        updateStudyStatus({
          chapter_id: this.chapter.id,
          resource_id: this.section.id,
        }).then(() => {
          this.$emit('finished');
          this.section.is_finish = 1;
        });
      }
    },
    getUserSavedCode() {
      getSaveCode({ contest_id: this.section.contest_id }).then((codeRes) => {
        if (codeRes.data && codeRes.data.code) {
          this.code = codeRes.data.code;
          this.language = codeRes.data.lang;
          this.$refs.ace.setContent(this.code);
          this.handleChangeProgramLang();
        }
      })
    },
    handleChangeProgramLang() {
      const lang = this.language
      this.$refs.ace.setLanguageMode(lang)
    }
  },
};
</script>

<style lang="scss">
.markdown-body {
  ol {
    list-style: decimal !important;
  }

  ul {
    list-style: disc !important;
  }
}

.problem-box {
  .el-tabs--border-card {
    box-shadow: none;
    height: 100%;
    overflow: hidden;

    .el-tabs__content {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }
}
.problem-box {
  .el-result__subtitle {
    p {
      white-space: pre-wrap;
    }
  }
}
</style>

<style lang="scss" scoped>
.problem-box.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
}

.problem-box {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);

  .left {
    .sample-box {
      margin-bottom: 20px;
      text-align: left;

      p {
        margin-bottom: 10px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
      }

      .result {
        font-size: 22px;
      }
    }
  }

  .right {
    background-color: #19161d;
  }

  .tool-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
  }

  .tool-btn {
    color: #5c5c5c;
  }

  .tool-btn:active {
    color: #409eff;
  }

  .icon2x {
    width: 1.5em !important;
    height: 1.5em !important;
  }
}
</style>