<template>
  <div class="homework-box flex h-full" :class="[isZoom ? 'fullscreen' : '']">
    <div class="left w-1/2">
      <el-tabs
        class="h-full"
        type="border-card"
        :stretch="true"
        v-model="tabActive"
      >
        <el-tab-pane class="mt-2 text-left" label="题目描述" name="description">
          <div class="markdown-body" v-html="markdown"></div>
          <el-steps
            class="mt-3"
            align-center
            :active="stepActiveIndex"
            finish-status="success"
          >
            <el-step
              v-for="(item, index) in section.content"
              :key="item.id"
              :title="`第${index + 1}页`"
            ></el-step>
          </el-steps>
        </el-tab-pane>
        <el-tab-pane label="运行结果" name="result">
          <div class="sample-box">
            <span>测试用例</span>
          </div>
          <div class="sample-box">
            <span>输入用例</span>
            <el-input
              type="textarea"
              v-model="contest.input_sample[0]"
              placeholder="请输入内容"
              resize="none"
            ></el-input>
          </div>
          <div class="sample-box">
            <span>输出用例</span>
            <el-input
              type="textarea"
              v-model="contest.output_sample[0]"
              placeholder="请输入内容"
              resize="none"
            ></el-input>
          </div>
          <div class="sample-box">
            <span class="result">运行结果</span>
            <el-input
              type="textarea"
              placeholder="请运行代码"
              rows="10"
              resize="none"
              v-model="resultOutput"
            ></el-input>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="right w-1/2">
      <div class="tool-box px-2">
        <div class="flex items-center">
          <el-select v-model="language" size="mini" style="width: 120px;">
            <el-option
              v-for="item in languageList"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-tooltip content="运行代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="runCode">
              <svg-icon
                class="ml-2 icon2x cursor-pointer"
                iconClass="play"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tag
            v-if="runCodeStatus === 0"
            class="ml-2"
            size="small"
            effect="dark"
            >待运行</el-tag
          >
          <el-tag
            v-else-if="runCodeStatus === 2"
            class="ml-2"
            type="success"
            size="small"
            effect="dark"
            >运行完成</el-tag
          >
          <el-tag v-else class="ml-2" type="warning" size="small" effect="dark"
            >运行中</el-tag
          >
        </div>
        <div class="flex">
          <el-tooltip content="上一页" placement="bottom" effect="light">
            <a class="tool-btn" @click="prev">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="direction-left"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip content="下一页" placement="bottom" effect="light">
            <a class="tool-btn" @click="next">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="direction-right"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip
            v-if="!isZoom"
            content="全屏"
            placement="bottom"
            effect="light"
          >
            <a class="tool-btn" @click="isZoom = true">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="zoom-in"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip
            v-if="isZoom"
            content="退出全屏"
            placement="bottom"
            effect="light"
          >
            <a class="tool-btn" @click="isZoom = false">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="zoom-out"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip content="还原代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="refreshCode">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="refresh"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip content="检查代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="checkFinalCodeCorrect">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="check-code"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tooltip content="保存代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="saveCode">
              <svg-icon
                class="icon2x ml-3 cursor-pointer"
                iconClass="save"
              ></svg-icon>
            </a>
          </el-tooltip>
        </div>
      </div>
      <ace-editor
        ref="ace"
        :config="aceEditorConfig"
        :content="code"
        @updateCodeValue="updateCodeVal"
        style="height: calc(100% - 40px); overflow: auto"
      ></ace-editor>
    </div>
    <link
      rel="stylesheet"
      href="https://haimakid-cdn.haimakid.net/web/css/github-markdown.min.css"
    />
  </div>
</template>

<script>
import AceEditor from "./AceEditor.vue";
import {
  section,
  contest,
  updateStudyStatus,
  updateHomeworkProgress,
  saveContestCode,
  runContestCode,
  checkCodeResult,
  getSaveCode,
} from "@/api/course";

import hljs from "highlight.js";
import "highlight.js/styles/xcode.css";

const MarkdownIt = require("markdown-it");
const MarkdownItKatex = require("markdown-it-katex");

export default {
  name: "Homework",
  components: {
    AceEditor,
  },
  data() {
    return {
      chapter: {
        id: 0,
      },
      section: {
        id: 0,
        type: 0,
        content: [],
      },
      contest: {
        id: 0,
        input_sample: [],
        output_sample: [],
      },
      aceEditorConfig: {
        maxLines: 200,
        minLines: 15,
      },
      language: "C++",
      languageList: [
        {
          id: 0,
          label: "C++",
        },
      ],
      code: "",
      stepActiveIndex: 0,
      contentActiveIndex: 0,
      tabActive: "description",
      markdownIt: null,
      isZoom: false,
      resultOutput: "",
      runCodeStatus: 0,
    };
  },
  computed: {
    markdown() {
      if (this.section.content.length > 0 && this.markdownIt) {
        if (this.contentActiveIndex >= this.section.content.length) {
          return this.markdownIt.render(
            this.section.content[this.section.content.length - 1].content
          );
        } else {
          return this.markdownIt.render(
            this.section.content[this.contentActiveIndex].content
          );
        }
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.chapter.id = Number(this.$route.params.id);
    this.section.id = this.$route.params.sid;
    this.markdownIt = new MarkdownIt({
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(str, { language: lang }).value;
          } catch (_) {
            console.log("hljs error");
          }
        }
        return "";
      },
    }).use(MarkdownItKatex);
    this.init();
  },
  methods: {
    init() {
      section({ id: this.section.id }).then((sectionRes) => {
        this.section = sectionRes.data;
        this.section.content = JSON.parse(this.section.content);
        if (this.$store.getters.eduRole === "student") {
          this.stepActiveIndex = this.section.active_index;
          this.contentActiveIndex = this.section.active_index;
          if (this.section.active_index === this.section.content.length - 1) {
            this.stepActiveIndex++;
          }
        }
        this.code = this.section.content[this.contentActiveIndex].code;
        this.$refs.ace.setContent(this.code);
        if (this.section.type === this.GLOBAL.homework) {
          contest({ id: this.section.contest_id }).then((contestRes) => {
            this.contest = contestRes.data;
            this.contest.input_sample = JSON.parse(this.contest.input_sample);
            this.contest.output_sample = JSON.parse(this.contest.output_sample);
          });
          this.getUserSavedCode()
        }
      });
    },
    checkTabsName() {
      if (this.tabActive === "result") {
        this.tabActive = "description";
      }
    },
    prev() {
      this.checkTabsName();
      if (this.stepActiveIndex > 0) {
        if (this.stepActiveIndex === this.section.content.length - 1) {
          this.stepActiveIndex--;
        }
        this.stepActiveIndex--;
        this.contentActiveIndex--;
      }
    },
    next() {
      this.checkTabsName();
      if (this.$store.getters.eduRole === "student") {
        if (this.contentActiveIndex < this.section.active_index) {
          if (this.stepActiveIndex <= this.section.content.length - 1) {
            this.stepActiveIndex++;
            this.contentActiveIndex++;
            if (this.stepActiveIndex === this.section.content.length - 1) {
              this.stepActiveIndex++;
              // 更新两个
              updateHomeworkProgress({
                chapter_id: this.chapter.id,
                resource_id: this.section.id,
                active_index: this.contentActiveIndex,
              }).then(() => {
                this.section.active_index = this.contentActiveIndex;
                this.finishStudy();
              });

            }
          }
        }
      } else {
        if (this.stepActiveIndex <= this.section.content.length - 1) {
          this.stepActiveIndex++;
          this.contentActiveIndex++;
          if (this.stepActiveIndex === this.section.content.length - 1) {
            this.stepActiveIndex++;
          }
        }
      }
    },
    refreshCode() {
      this.$refs.ace.setContent(
        this.section.content[this.contentActiveIndex]?.code
      );
    },
    updateCodeVal(val) {
      this.code = val;
      this.checkCodeCorrect();
    },
    checkCodeCorrect() {
      if (this.contentActiveIndex < this.section.content.length - 1) {
        const answerCode = this.code.replace(/[\s\r\n]/g, "");
        const originCode = this.section.content[
          this.contentActiveIndex + 1
        ].code.replace(/[\s\r\n]/g, "");
        if (answerCode === originCode) {
          // 请求后台，记录学习进度
          if (this.$store.getters.eduRole === "student") {
            if (this.section.is_finish === 0) {
              updateHomeworkProgress({
                chapter_id: this.chapter.id,
                resource_id: this.section.id,
                active_index: this.contentActiveIndex + 1,
              }).then(() => {
                this.section.active_index = this.contentActiveIndex + 1;
                this.next();
              });
            } else {
              this.next();
            }
          } else {
            this.next();
          }
        }
      }
    },
    runCode() {
      if (this.runCodeStatus !== 1) {
        this.runCodeStatus = 1;
        this.resultOutput = "";
        let timer = null;
        runContestCode({
          contest_id: this.contest.id,
          code: this.code,
          input_sample: this.contest.input_sample[0],
          output_sample: this.contest.output_sample[0],
          lang: this.language
        })
          .then(() => {
            let i = 0;
            timer = setInterval(() => {
              checkCodeResult({
                contest_id: this.contest.id,
              }).then((res) => {
                i++;
                if (res.data.status === 2) {
                  clearInterval(timer);
                  this.resultOutput = res.data.result_output
                    ? res.data.result_output
                    : res.data.result_err_msg;
                  this.tabActive = "result";
                  this.runCodeStatus = 2;
                } else if (i === 10) {
                  clearInterval(timer);
                  this.$message({
                    message: "运行超时，请稍后重试",
                    type: "error",
                  });
                  this.runCodeStatus = 0;
                }
              });
            }, 1500);
          })
          .catch(() => {
            clearInterval(timer);
            this.runCodeStatus = 0;
          });
      } else {
        this.$message({
          message: '代码正在运行中，请稍后重试',
          type: 'warning'
        })
      }
    },
    saveCode() {
      saveContestCode({
        contest_id: this.contest.id,
        code: this.code,
        input_sample: this.contest.input_sample[0],
        output_sample: this.contest.output_sample[0],
        lang: this.language,
      }).then((res) => {
        console.log(res.msg);
      });
    },
    finishStudy() {
      if (this.section.is_finish === 0) {
        updateStudyStatus({
          chapter_id: this.chapter.id,
          resource_id: this.section.id,
        }).then(() => {
          this.$emit('finished');
          this.section.is_finish = 1;
        });
      }
    },
    getUserSavedCode() {
      getSaveCode({ contest_id: this.section.contest_id }).then((codeRes) => {
        if (codeRes.data && codeRes.data.code) {
          this.code = codeRes.data.code;
          this.$refs.ace.setContent(this.code);
        }
      })
    },
    // 检查当前代码是否已经是最后的结果，如果是则直接记录完成
    checkFinalCodeCorrect() {
      const answerCode = this.code.replace(/[\s\r\n]/g, "");
      const originCode = this.section.content[
        this.section.content.length - 1
      ].code.replace(/[\s\r\n]/g, "");
      if (answerCode === originCode) {
        // 请求后台，记录学习进度
        if (this.$store.getters.eduRole === "student") {
          this.$message({
            type: 'success',
            message: '恭喜你，代码正确!',
          })
          if (this.section.is_finish === 0) {
            const tempIndex = this.section.content.length - 1;
            updateHomeworkProgress({
              chapter_id: this.chapter.id,
              resource_id: this.section.id,
              active_index: tempIndex,
            }).then(() => {
              this.section.active_index = tempIndex;
              this.contentActiveIndex = tempIndex;
              this.stepActiveIndex = tempIndex + 1;
              this.finishStudy();
              this.saveCode();
            });
          } else if (this.section.is_finish === 1) {
            const tempIndex = this.section.content.length - 1;
            this.section.active_index = tempIndex;
            this.contentActiveIndex = tempIndex;
            this.stepActiveIndex = tempIndex + 1;
          }
        } else {
          const tempIndex = this.section.content.length - 1;
          this.section.active_index = tempIndex;
          this.contentActiveIndex = tempIndex;
          this.stepActiveIndex = tempIndex + 1;
        }
      } else {
        this.$message({
          type: 'warning',
          message: '代码不正确，请仔细检查代码!',
        })
        console.log(originCode);
      }
      
    }
  },
};
</script>

<style lang="scss">
.markdown-body {
  ol {
    list-style: decimal !important;
  }
  ul {
    list-style: disc !important;
  }
}
.homework-box {
  .el-tabs--border-card {
    box-shadow: none;
    height: 100%;
    overflow: hidden;

    .el-tabs__content {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }
  .el-step__title {
    word-break: keep-all;
  }
}
</style>

<style lang="scss" scoped>
.homework-box.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
}
.homework-box {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
  .left {
    .el-tabs--border-card {
      box-shadow: none;
    }

    .sample-box {
      margin-bottom: 20px;
      text-align: left;

      span {
        font-size: 18px;
        font-weight: bold;
      }

      .result {
        font-size: 22px;
      }
    }
  }
  .right {
    background-color: #19161d;
  }

  .tool-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
  }

  .tool-btn {
    color: #5c5c5c;
  }
  .tool-btn:active {
    color: #409eff;
  }

  .icon2x {
    width: 1.5em !important;
    height: 1.5em !important;
  }
}
</style>