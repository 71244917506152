<template>
  <div class="reveal">
    <div class="slides">
      <section data-markdown :data-background="background">
        <textarea data-template v-html="content"></textarea>
      </section>
    </div>
  </div>
</template>

<script>
import Reveal from "reveal.js";
import Markdown from "reveal.js/plugin/markdown/markdown";
import Highlight from "reveal.js/plugin/highlight/highlight";
import Math from "reveal.js/plugin/math/math";
import "reveal.js/plugin/highlight/monokai.css";

import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/sky.css";

import * as yamlFront from "yaml-front-matter";

import { section, updateStudyStatus } from "@/api/course";

export default {
  name: "PowerPoint",
  data() {
    return {
      content: "123",
      background: "",
      reveal: null,
      section: null,
      chapter: {
        id: 0,
      }
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.chapter.id = Number(this.$route.params.id)
      section({ id: this.$route.params.sid }).then((res) => {
        this.section = res.data;
        this.finishStudy();
        const temp = yamlFront.loadFront(this.section.content);
        if (temp) {
          this.content = temp.__content;
          this.background = temp.background;
        }
        this.$nextTick(() => {
          this.reveal = new Reveal(document.querySelector(".reveal"), {
            katex: {
              local: 'https://haimakid-cdn.haimakid.net/web/js/katex' 
            },
            plugins: [Markdown, Highlight, Math.KaTeX],
          });
          this.reveal.initialize();
        });
      });
    },
    finishStudy() {
      if (this.section.is_finish === 0) {
        updateStudyStatus({
          chapter_id: this.chapter.id,
          resource_id: this.section.id,
        }).then(() => {
          this.section.is_finish = 1;
          this.$emit('finished');
        });
      }
    },
  },
  destroyed() {
    console.log("destroyed");
    this.reveal.destroy();
    this.reveal = null;
  },
};
</script>

<style lang="scss">
.reveal table {
  margin: 0 !important;
}
.reveal {
  font-size: 26px;
}
.reveal .slides {
  text-align: left;
}
.reveal table {
  margin: 0 !important;
}
.reveal pre {
  margin: 0 !important;
  width: 100% !important;
}
.reveal pre code {
  font-size: 22px;
  line-height: 23px;
}
.reveal p code {
  padding: 2px 2px;
  color: #c7254e;
  border-radius: 2px;
}
.reveal h1,
.reveal h2,
.reveal h3,
.reveal h4,
.reveal h5 {
  letter-spacing: 1px;
  text-transform: none;
}
</style>