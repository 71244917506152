<!--
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-08-23 17:35:56
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-13 18:41:12
 * @FilePath: /haimakid-xinao/src/views/chapter/index.vue
-->
<template>
  <div class="chapter-container">
    <navbar :isContainer="false"></navbar>
    <el-container style="height: calc(100vh - 60px)">
      <el-header class="flex items-center border border-gray-200">
        <h2>{{ course.name }}</h2>
        <el-dropdown class="ml-4">
          <span class="el-dropdown-link">{{ chapter.name }} <i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :class="[item.id === chapter.id?'dropdown-text-active': '']" v-for="item in dropdownList" :key="item.id" @click.native="goToChapter(item)">{{ item.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div v-if="$store.getters.eduRole === 'student'" style="margin-left: auto;">
          <el-button type="primary" size="small" @click="handelShowRanking"><i class="el-icon-s-data"></i>班级学习榜</el-button>
        </div>
        <div v-else style="margin-left: auto;">
          <el-select class="mr-2" v-model="currentClassID" placeholder="请选择班级" size="mini">
            <el-option v-for="item in classList" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
          <el-button type="warning" size="mini" @click="goToStudyStatus">查看学习情况</el-button>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 60px)">
        <el-aside class="aside-box bg-gray-100 border-r border-gray-200 overflow-auto">
          <el-image class="w-full p-2 bg-white" :src="course.cover" style="height: 150px"></el-image>
          <el-tree class="tree-box bg-gray-100" :data="treeList" default-expand-all @node-click="showDetail">
            <div class="custom-tree-node w-full" slot-scope="{ node, data }">
              <div class="flex items-center" :class="[currentTreeNode.id === data.id?'node-active':'']">
                <template v-if="$store.getters.eduRole === 'student'">
                  <svg-icon v-if="data.isFinish === 1" iconClass="finish-check" class="mr-2" />
                  <svg-icon v-else iconClass="finish-minus" class="mr-2" />
                </template>
                <svg-icon v-if="data.type === GLOBAL.problem" class="mr-2" iconClass="code" /> 
                <svg-icon v-else-if="data.type === GLOBAL.homework" class="mr-2" iconClass="transfer" /> 
                <svg-icon v-else-if="data.type === GLOBAL.question" class="mr-2" iconClass="choice" /> 
                <svg-icon v-else-if="data.type === GLOBAL.ppt" class="mr-2" iconClass="ppt" /> 
                {{ node.label }}
              </div>
            </div>
          </el-tree>
        </el-aside>
        <el-main style="width: calc(100% - 302px); height: 100%;">
          <div class="main-box">
            <power-point v-if="currentTreeNode.type === GLOBAL.ppt" :key="currentTreeNode.id" @finished="handleFinished(currentTreeNode)"></power-point>
            <homework v-if="currentTreeNode.type === GLOBAL.homework" :key="currentTreeNode.id" @finished="handleFinished(currentTreeNode)"></homework>
            <problem v-if="currentTreeNode.type === GLOBAL.problem" :key="currentTreeNode.id" @finished="handleFinished(currentTreeNode)"></problem>
            <question v-if="currentTreeNode.type === GLOBAL.question" :key="currentTreeNode.id" @finished="handleFinished(currentTreeNode)"></question>
            
          </div>
          <div class="drawer-btn">
            
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-drawer title="班级学习榜" :visible.sync="drawerVisible">
      <LearnRanking ref="ranking" :courseId="course.id" :sectionId="section.id" />
    </el-drawer>
  </div>
</template>

<script>

import Navbar from '../../layout/components/Navbar.vue'
import PowerPoint from './components/PowerPoint.vue'
import Homework from './components/Homework.vue'
import Problem from './components/Problem.vue'
import Question from './components/Question.vue'
import LearnRanking from './components/LearnRanking.vue'

import { chapter } from '@/api/course'
import { getClassList } from '@/api/backage'
import checkPermission from '@/utils/permission'

export default {
  components: {
    Navbar,
    PowerPoint,
    Homework,
    Problem,
    Question,
    LearnRanking,
  },
  data() {
    return {
      course: {
        id: 0,
        name: '',
        cover: '',
      },
      chapter: {
        id: 0,
        name: ''
      },
      section: {
        id: 0,
        type: 0,
      },
      dropdownList: [],
      treeList: [],
      currentTreeNode: {
        id: 0,
        type: 0
      },
      classList: [],
      currentClassID: '',
      drawerVisible: false,
    }
  },
  watch: {
    $route() {
      this.chapter.id = Number(this.$route.params.id)
      this.section.id = Number(this.$route.params.sid)
      this.init()
    }
  },
  mounted() {
    this.chapter.id = Number(this.$route.params.id)
    this.section.id = Number(this.$route.params.sid)
    this.init()
  },
  methods: {
    init() {
      chapter({ id: this.chapter.id }).then(res => {
        this.course = res.data.course
        this.chapter = res.data.chapter
        this.dropdownList = res.data.course.chapters.map(item => {
          return { id: item.id, name: item.name, section: item.section }
        })
        this.treeList = [{
          id: this.chapter.id,
          label: this.chapter.name
        }]
        this.treeList[0].children = res.data.chapter.sections?.map(item => {
          return { id: item.id, label: item.name, type: item.type, isFinish: item.is_finish }
        })
        if (this.treeList[0].children.length > 0) {
          this.currentTreeNode = this.treeList[0].children.find(item => {
            return item.id === this.section.id
          })
        }
      })
      if (checkPermission(['teacher', 'admin'])) {
        getClassList().then(res => {
          this.classList = res.data.list.map(item => {
            return {
              id: item.id,
              label: item.name,
            }
          })
        })
      }
    },
    showDetail(item) {
      if (item?.type) {
        this.currentTreeNode = item
        this.$router.push({ name: 'ChapterIndex', params: { id: this.chapter.id, sid: item.id }})
      }
    },
    goToChapter(item) {
      this.$router.push({ name: 'ChapterIndex', params: { id: item.id, sid: item.section.id }})
    },
    goToStudyStatus() {
      if (!this.currentClassID) {
        this.$message({
          message: '请先选择班级',
          type: 'warning'
        })
      } else {
        this.$router.push({ name: 'StudyStatusList', params: { sid: this.chapter.id, cid: this.currentClassID }})
      }
    },
    handleFinished(node) {
      let current = this.treeList[0].children.find((item) => item.id === node.id);
      if (current && current.isFinish === 0) {
        current.isFinish = 1;
        this.treeList = JSON.parse(JSON.stringify(this.treeList))
      }
    },
    handelShowRanking() {
      this.drawerVisible = true
      this.$nextTick(() => {
        this.$refs.ranking.init()
      })
    }
  }
}
</script>
<style>
  .chapter-container {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .chapter-container .el-tree-node__content {
    height: 36px;
  }
  .chapter-container .el-tree-node__label {
    font-size: 16px;
  }
  .dropdown-text-active {
    color: #409EFF;
  }
  .katex .katex-mathml {
    display: none;
  }
</style>
<style lang="scss" scoped>
  .main-box {
    margin: 0 auto;
    width: calc(((100vh - 170px) * 16) / 9);
    height: 100%;
  }
  .custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .node-active {
      color: #409EFF;
    }
  }
  .aside-box {
    height: calc(100vh - 120px);
    overflow: hidden;
    .tree-box {
      height: calc(100% - 164px);
      overflow-y: auto;
    }
  }
</style>